.icono {
    text-align: center;
}

.icono2 {
    width: 20%;
}

.contenido {
    text-align: center;
}